import { FC, useContext, useEffect, useState } from 'react';

import { Form, Select } from 'antd';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';

import Loader from 'common/Loader/Loader';
import { IModule, IPlan, Subscriptions, isModule } from 'connectors/query/Subscriptions';
import Button from 'common/Button/Button';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import { Documents, IDocumentData } from 'connectors/query/Documents';
import DocumentModal from 'common/DocumentModal/DocumentModal';
import { openErrorNotification } from 'common/Notifications/Notifications';

import _ from './PayForm.module.css';

type DocumentType = 'privacyPolicy' | 'paymentRules' | 'refund' | 'refusal';
interface ISelectOption {
    value: number;
    label: string;
}
interface IPayFormProps {
    sum?: string;
    service_name?: string;
    type: 'project' | 'hotline';
    projectId?: string;
    moduleId?: number;
    planId?: number | null;
    closeModal: () => void;
}

const PayForm: FC<IPayFormProps> = ({ sum, service_name, type, projectId, moduleId, planId, closeModal }) => {
    const { userData } = useContext(AuthContext);
    const [form] = Form.useForm();
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
    const [selectPlanOptions, setSelectPlanOptions] = useState<ISelectOption[]>([]);
    const [showDoc, setShowDoc] = useState<DocumentType | null>(null);
    const [documentData, setDocumentData] = useState<IDocumentData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getPlansData();
    }, []);

    useEffect(() => {
        selectedPlan &&
            form.setFieldsValue({
                sum: `${selectedPlan.price} ₽`,
                service_name: selectedPlan.name,
            });
    }, [selectedPlan]);

    const getPlansData = async () => {
        let result;
        let module;
        setIsLoading(true);
        if (type === 'hotline') {
            result = await Subscriptions.getModules();
        }
        if (type === 'project' && moduleId) {
            result = await Subscriptions.getModule(moduleId);
        }

        if (result) {
            if (Array.isArray(result)) {
                module = result.find((module: IModule) => module.ptype === 'HTL');
            } else {
                module = result;
            }
            if (module && isModule(module) && module.plans[0]) {
                if (planId) {
                    const previousPlan = module.plans.find((plan: IPlan) => plan.id === planId);
                    if (previousPlan) {
                        setSelectedPlan(previousPlan);
                    } else {
                        closeModalCauseError();
                    }
                } else {
                    setSelectedPlan(module.plans[0]);
                }

                setPlans(module.plans);
                setSelectPlanOptions(module.plans.map((plan, num) => ({ value: num, label: plan.name })));
            }
        } else {
            closeModalCauseError();
        }
        setIsLoading(false);
    };

    const onFinish = async (values: any) => {
        if (!selectedPlan) return openErrorNotification('Ошибка оплаты.');

        const sub = await Subscriptions.createSubscription({
            plan_id: selectedPlan.id,
            status: 'PND',
            is_trial: false,
            ...(type === 'project' ? { project_id: Number(projectId) } : {}),
        });

        if (!sub.id) return openErrorNotification('Ошибка оплаты.');

        const { client_email, sum, service_name, clientid, client_phone } = values;

        const formElement = document.createElement('form');
        formElement.action = 'https://movie-right.server.paykeeper.ru/create/';
        // PROD
        // formElement.action = 'https://movie-right-test.server.paykeeper.ru/create/';
        formElement.method = 'POST';
        formElement.style.display = 'none';

        const fields = [
            { name: 'client_email', value: client_email },
            { name: 'client_phone', value: client_phone },
            { name: 'sum', value: (sum as string).replace(' ₽', '') },
            { name: 'service_name', value: service_name },
            { name: 'clientid', value: clientid },
            { name: 'orderid', value: sub.id },
        ];

        fields.forEach(({ name, value }) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            input.value = value;
            formElement.appendChild(input);
        });

        document.body.appendChild(formElement);
        formElement.submit();
        document.body.removeChild(formElement);
    };

    const getDocumentData = async (docType: DocumentType): Promise<IDocumentData | null> => {
        const res = await Documents[docType]();
        if (res?.content) {
            setDocumentData(res);
            return res;
        }
        return null;
    };

    const openDocumentModal = async (docType: DocumentType) => {
        setShowDoc(null);
        setDocumentData(null);
        const res = await getDocumentData(docType);
        if (res) {
            setShowDoc(docType);
        }
    };

    const closeModalCauseError = () => {
        closeModal();
        openErrorNotification('Не удалось получить тариф.');
    };

    return (
        <div className={_.formBlock}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Form
                        form={form}
                        name='payform'
                        initialValues={{
                            client_email: userData?.email || '',
                            client_phone: userData?.phone || '',
                            sum,
                            service_name,
                            clientid: `${userData?.first_name} ${userData?.last_name}`,
                        }}
                        action='https://movie-right.server.paykeeper.ru/create/'
                        // PROD
                        // action='https://movie-right-test.server.paykeeper.ru/create/'
                        method='POST'
                        onFinish={onFinish}
                        onError={(error) => {
                            console.error('form error:', error);
                        }}
                    >
                        <Row>
                            <Col span={17}>
                                {userData?.id && (
                                    <MyFormItem
                                        name={['client_email']}
                                        label='E-mail'
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Введён некорректный e-mail',
                                            },
                                            {
                                                required: true,
                                                message: 'Пожалуйста, укажите e-mail',
                                            },
                                        ]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: 480 }}
                                    >
                                        <Input className={_.elParam} readOnly />
                                    </MyFormItem>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col span={17}>
                                <MyFormItem
                                    name={['sum']}
                                    label='Цена'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ width: 480 }}
                                >
                                    <Input className={_.elParam} readOnly />
                                </MyFormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={17}>
                                <MyFormItem
                                    name={['service_name']}
                                    label='Название тарифа'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ width: 480 }}
                                >
                                    {plans.length < 2 || planId ? (
                                        <Input className={_.elParam} readOnly />
                                    ) : (
                                        <Select
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(plan) => {
                                                setSelectedPlan(plans[plan]);
                                            }}
                                            className='select select4'
                                            options={selectPlanOptions}
                                        />
                                    )}
                                </MyFormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={17}>
                                <MyFormItem
                                    name={['clientid']}
                                    label='clientid'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ width: 480 }}
                                    className={_.hiddenInput}
                                >
                                    <Input className={_.elParam} readOnly />
                                </MyFormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={17}>
                                <MyFormItem
                                    name={['client_phone']}
                                    label='client_phone'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ width: 480 }}
                                    className={_.hiddenInput}
                                >
                                    <Input className={_.elParam} readOnly />
                                </MyFormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={17}>
                                <MyFormItem
                                    name={['orderid']}
                                    label='Номер заказа'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ width: 480 }}
                                    className={_.hiddenInput}
                                >
                                    <Input className={_.elParam} readOnly />
                                </MyFormItem>
                            </Col>
                        </Row>

                        <MyFormItem style={{ marginBottom: 50 }}>
                            <Button width='480px' htmlType='submit'>
                                Оплатить
                            </Button>
                        </MyFormItem>
                    </Form>

                    <div className={_.docsForm}>
                        Нажимая кнопку Оплатить вы соглашаетесь с правилами сайта
                        <div className={_.paymentRules} onClick={() => openDocumentModal('privacyPolicy')}>
                            Политика конфиденциальности
                        </div>
                        <div className={_.paymentRules} onClick={() => openDocumentModal('paymentRules')}>
                            Описание оплаты товара
                        </div>
                        <div className={_.paymentRules} onClick={() => openDocumentModal('refund')}>
                            Возврат товара
                        </div>
                        <div className={_.paymentRules} onClick={() => openDocumentModal('refusal')}>
                            Отказ от услуги
                        </div>
                    </div>

                    <DocumentModal
                        isOpen={!!showDoc && !!documentData}
                        onClose={() => {
                            setShowDoc(null);
                            setDocumentData(null);
                        }}
                        title={documentData?.name}
                        content={documentData?.content}
                    />
                </>
            )}
        </div>
    );
};

export default PayForm;
